// WeatherSec.js
import React, { useEffect, useState } from 'react';
import d from "../imgs/8marsi.jpg";
import f from "../imgs/love.jpg"
import g from "../imgs/17shkurt.jpg"

const WeatherSec = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const getWeatherData = async () => {
      try {
        // Replace 'YOUR_API_KEY' with the actual API key you obtained from OpenWeatherMap
        const apiKey = 'bd98722be0c7b4f17aafdf11894e1f41';
        const city = 'Prizren';
        const countryCode = 'XK';
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city},${countryCode}&appid=${apiKey}&units=metric`;

        const response = await fetch(apiUrl);
        const data = await response.json();

        if (response.ok) {
          setWeatherData(data);
        } else {
          console.error('Error fetching weather data:', data.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching weather data:', error.message || 'Unknown error');
      }
    };

    getWeatherData();
  }, []);

  return (
    <div className="container-fluid" id="gray">
      <div className="container weather-class">
        {weatherData && (
          <div className="row">
            <div className="col-lg-4 text-center weather-sec">
              <h1>{new Date().toLocaleDateString()}</h1>
              <p>Data</p>
            </div>
            <div className="col-lg-4 text-center weather-sec">
              <h1>{new Date().toLocaleTimeString()}</h1>
              <p>Koha reale</p>
            </div>
            <div className="col-lg-4 text-center weather-sec">
            <h1>{weatherData.main.temp.toFixed(1)}°C</h1>
              <p>Moti</p>
            </div>
          </div>
        )}
      
      </div>
    </div>
  );
};

export default WeatherSec;
