import React, { useState, useEffect } from 'react';
import img5 from "../imgs/icons8-top-24.png";

const ToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      return () => {
        window.removeEventListener('scroll', toggleVisibility);
      };
    }, []);
  return (
    <div>      
        {isVisible && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
         <img src={img5} alt />
        </button>
      )}
    
 
      
      </div>

  )
}

export default ToTopButton