import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Rooms from './pages/Rooms';
import Details from './pages/Details';
import Bar from './pages/bar';
import Spa from './pages/spa';
import Wedding from './pages/wedding';
import Restaurant from './pages/restaurant';
import Navbar from './components/NavbarCompnoonent';
import Menu from './pages/menu';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/rooms" component={Rooms} />
            <Route path="/details" component={Details} />
            <Route path="/bar" component={Bar} />
            <Route path="/spa" component={Spa} />
            <Route path="/wedding" component={Wedding} />
            <Route path="/restaurant" component={Restaurant} />
            <Route path="/menu" component={Menu} />
          </Switch>
      </Router>
    </div>
  );
}

export default App;
