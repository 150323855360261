import React, { useRef, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Navbar from '../components/NavbarCompnoonent'
import Footer from '../components/Footer'
import img1 from "../imgs/res1.JPG";
import img2 from "../imgs/res2.JPG";
import img3 from "../imgs/res3.JPG";
import img4 from "../imgs/res4.JPG";
import img5 from "../imgs/res5.JPG";
import img6 from "../imgs/res6.JPG";
import img7 from "../imgs/res7.jpg";
import img8 from "../imgs/res8.JPG";
import img9 from "../imgs/res9.JPG";
import startsicon from "../imgs/five-star-customer-product-ratings-review-flat-icons-for-apps-and-websites-illustration-of-five-golden-yellow-stars-in-a-row-isolated-in-a-white-background-concepts-for-ratings-customers-revie-removeb.png"

import ToTopButton from '../components/ToTopButton';

const Restaurant = () => {
    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img
                        src={img1}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img2}
                    alt="..."
                /></SwiperSlide>
                <SwiperSlide>
                    <img
                        src={img3}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img4}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img5}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img6}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img7}
                    alt="..."
                /></SwiperSlide>
                     <SwiperSlide>                    
                    <img
                    src={img8}
                    alt="..."
                /></SwiperSlide>
                     <SwiperSlide>                    
                    <img
                    src={img9}
                    alt="..."
                /></SwiperSlide>
            </Swiper>
            <div className='container details-full'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-12 left-detail-part' data-aos="fade-down" data-aos-duration="1000">
                        <img src={startsicon} />
                        <h6>LAKESIDE HOTEL</h6>
                        <h1>Restauranti</h1>
                        <br />
                        <p>Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien vel conseyen
                            turpeutionyer masin libero sevenion vusetion viventa augue sit amet hendrerit vestibulum. Duisteyerion
                            venenatis lacus gravida eros ut turpis interdum ornare.</p>
                        <br />

                        <p>Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien vel conseyen
                            turpeutionyer masin libero sevenion vusetion viventa augue sit amet hendrerit vestibulum. Duisteyerion
                            venenatis lacus gravida eros ut turpis interdum ornare.</p>
                      
                        <br /><br />
                        <h2>Special check-in instructions</h2>
                        <p>Guests will receive an email 5 days before arrival with check-in instructions; front desk staff will greet guests on arrival For more details, please contact the property using the information on the booking confirmation.</p>
                    </div>
                  
                </div>
            </div>
            <br /><br/>

            <ToTopButton/>
            <Footer />
        </>
    )
}

export default Restaurant