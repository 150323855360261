import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import a from "../imgs/IMG_0593.jpg"
import b from "../imgs/IMG_0693.jpg"
import c from "../imgs/IMG_0603 copy.jpg"
import d from "../imgs/image00004.jpeg"
import e from "../imgs/image00006.jpeg"
import f from "../imgs/IMG_0647.jpg"


const Posts = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div id="gray">
      <div className="container" >
        <div className="row post-texts">
          <h4
            className="title-post"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Discover
          </h4>
          <h1
            className="second-title-post"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Exploro <span>SPA-në</span> dhe <br /> pishinën <span> e brendshme</span>{" "}
          
          </h1>
        </div>
        <div className="row post-imgs">
          <div className="col-lg-4">
            <img
              src={a}
              alt=""
              data-aos="fade-down"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-4">
            <img
              src={b}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-4 " id="margin-of-post">
            <img
              src={c}
              alt=""
              data-aos="fade-down"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div className="row post-imgs">
          <div className="col-lg-4">
            <img
              src={d}
              alt=""
              data-aos="fade-down"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-4">
            <img
              src={e}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-4 " id="margin-of-post">
            <img
              src={f}
              alt=""
              data-aos="fade-down"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Posts;
