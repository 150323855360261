import React, { useRef, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Navbar from '../components/NavbarCompnoonent'
import Footer from '../components/Footer'
import img1 from "../imgs/bar1.jpg";
import img2 from "../imgs/bar2.jpg";
import img3 from "../imgs/bar3.jpg";
import img4 from "../imgs/bar4.jpg";
import img5 from "../imgs/bar5.jpg";
import img6 from "../imgs/bar6.jpg";
import img7 from "../imgs/bar7.jpg";
import startsicon from "../imgs/five-star-customer-product-ratings-review-flat-icons-for-apps-and-websites-illustration-of-five-golden-yellow-stars-in-a-row-isolated-in-a-white-background-concepts-for-ratings-customers-revie-removeb.png"
import ToTopButton from '../components/ToTopButton';

const Bar = () => {
    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img
                        src={img1}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img2}
                    alt="..."
                /></SwiperSlide>
                <SwiperSlide>
                    <img
                        src={img3}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img4}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img5}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img6}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img7}
                    alt="..."
                /></SwiperSlide>
            </Swiper>
            <div className='container details-full'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-12 left-detail-part' data-aos="fade-down" data-aos-duration="1000">
                        <img src={startsicon} />
                        <h6>LAKESIDE HOTEL</h6>
                        <h1>Bari i natës</h1>
                        <br />
                        <p>Bari i Natës është një oaz i qetësisë dhe elegancës, i dizajnuar me kujdes për të përshtatur me stilin dhe mbi të gjitha për të plotësuar pritjet më të larta të mysafirëve tanë të çmuar. Ambienti i ndriçuar me drita të buta dhe elementët e dizajnit modern krijojnë një atmosferë të ngrohtë dhe mikpritëse.</p>
                        <br />

                        <p>Në këtë ambient ekskluziv, barmenët tanë të kualifikuar janë gati t'ju shërbejnë me kreativitetin e tyre unik, duke përgatitur koktejle të shijshme dhe pije të zgjedhura me kujdes. Specialitetet e Barit të Natës përfshijnë një gamë të gjerë të pijeve premium, të shoqëruara me petkun e muzikës së zgjedhur me kujdes për të plotësuar eksperiencën tuaj të vizitës.</p>
                      
                        <br /><br />
                       
                        <p>Hyrni në një botë të pazakontë të shijes dhe elegance në Barin tonë të Natës, ku çdo gotë është një përjetim i veçantë dhe çdo moment është një kujtim që do të mbetet gjatë.</p>
                    </div>
                  
                </div>
            </div>
            <br /><br/>

            <ToTopButton/>
            <Footer />
        </>
    )
}

export default Bar