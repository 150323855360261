import React from "react";
import "../index.css";
import HomeBanner from "../components/HomeBanner";
import Navbar from "../components/NavbarCompnoonent";
import Booking from "../components/BookingSec";
import AboutUs from "../components/AboutUs";
import BookCards from "../components/BookCards";
import BannerSecSec from "../components/BannerSec";
import WeatherSec from "../components/WeatherSec";
import Feature from "../components/Feature";
import ExclusiveOffers from "../components/ExclusiveOffers";
import Posts from "../components/Posts";
import MapGoogle from "../components/MapGoogle";
import Footer from "../components/Footer";
import ToTopButton from "../components/ToTopButton";
import WhatsAppButton from "../components/whats";
import Offers from "../components/offers";
import Festat from "../components/festat";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <Booking />
      <WeatherSec />
      <Festat />
      {/* <Offers /> */}
      <AboutUs />

      <Feature />
      <ToTopButton />
      <BannerSecSec />
      <BookCards />
      <Posts />
      <ExclusiveOffers />
      <MapGoogle />
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default Home;
