import React from "react";
import Navbar from "../components/NavbarCompnoonent";
import Footer from "../components/Footer";
import img1 from "../imgs/icons8-users-32.png";
import img2 from "../imgs/icons8-wifi-50.png";
import img3 from "../imgs/icons8-bed-80.png";
import img4 from "../imgs/icons8-breakfast-64.png";
import img5 from "../imgs/icons8-square-border-24.png";
import img6 from "../imgs/icons8-pool-50.png";
import starsicon from "../imgs/five-star-customer-product-ratings-review-flat-icons-for-apps-and-websites-illustration-of-five-golden-yellow-stars-in-a-row-isolated-in-a-white-background-concepts-for-ratings-customers-revie-removeb.png"
import { Link } from "react-router-dom";
import ToTopButton from "../components/ToTopButton";
import a from "../imgs/Sandart Room.jpg"
import b from "../imgs/twen rom.jpg"
import c from "../imgs/Apartament.jpg"
import d from "../imgs/Quin Room (2).jpg"
import e from "../imgs/_DSC6190.jpg"
import f from "../imgs/Egzekutiv Room.jpg"
import g from "../imgs/Pamje e sweets.jpg"

const Rooms = () => {
    return (
        <div>
            <div className="container-fluid bg-rooms-entry">
                <img src={starsicon} data-aos="fade-up" data-aos-duration="1000" />
                <p data-aos="fade-up" data-aos-duration="1000">LAKESIDE HOTEL</p>
                <h1 data-aos="fade-up" data-aos-duration="1000">Dhomat dhe Suitat</h1>
            </div>
            <div className="container mt-5">
                <div className="row hotel-cards">
                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                                <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={a}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                                <div class="col-md-6 border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>89€ </span> / Nata</h6>
                                        <h2 class="card-title">Dhoma Standarde</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>1-2 Persons</p>
                                                </li>
                                               
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio Bed</p>
                                                </li>
                                            
                                            </ul>
                                     
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0 unique">
                                <div class="col-md-6  border-0" data-aos="fade-up" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>89€ </span> / Nata</h6>
                                        <h2 class="card-title">Dhoma Twin</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>2 Persons</p>
                                                </li>
                                               
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Twin Bed</p>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                        <hr />
                                      <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                                <div class="col-md-6" data-aos="fade-down" data-aos-duration="1000">
                                    <img
                                        src={b}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                            <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={c}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                                <div class="col-md-6  border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>109€ </span> / Nata</h6>
                                        <h2 class="card-title">Apartment</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>1-2 Persons</p>
                                                </li>
                                                
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio + Single Bed</p>
                                                </li>
                                                </ul>
                                                <ul>
                                                <li>
                                                    <img src={img4}></img>
                                                    <p>Breakfast</p>
                                                </li>
                                            </ul>
                                            
                                          
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                               
                                <div class="col-md-6  border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>129€ </span> / Nata</h6>
                                        <h2 class="card-title">Queen Room</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>2 Persons</p>
                                                </li>
                                                
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio + Living Room</p>
                                                </li>
                                                </ul>
                                                <ul>
                                                <li>
                                                    <img src={img4}></img>
                                                    <p>Breakfast</p>
                                                </li>
                                            </ul>
                                            
                                          
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                                <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={d}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                            <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={e}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                                <div class="col-md-6  border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>189€ </span> / Nata</h6>
                                        <h2 class="card-title">Junior Suite</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>1-2 Persons</p>
                                                </li>
                                                
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio</p>
                                                </li>
                                                </ul>
                                                <ul>
                                                <li>
                                                    <img src={img4}></img>
                                                    <p>Breakfast</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img6}></img>
                                                    <p>Jakuzzi</p>
                                                </li>
                                            </ul>
                                          
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                               
                                <div class="col-md-6  border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                        <h6><span>289€ </span> / Nata</h6>
                                        <h2 class="card-title">Executive Suite </h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>1-2 Persons</p>
                                                </li>
                                                <li>
                                                    <img src={img4}></img>
                                                    <p>Breakfast</p>
                                                </li>
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                                <li>
                                                    <img src={img6}></img>
                                                    <p>Jakuzzi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio + Cuisine + Living Room</p>
                                                </li>
                                              
                                                </ul>
                                         
                                          
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                                <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={f}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-12">
                        <div class="card mb-5 border-0">
                            <div class="row g-0 border-0" id="flex-direction-hotel">
                            <div class="col-md-6" data-aos="fade-up" data-aos-duration="1000">
                                    <img
                                        src={g}
                                        class="img-fluid"
                                        alt="..."
                                    />
                                </div>
                                <div class="col-md-6  border-0" data-aos="fade-down" data-aos-duration="1000">
                                    <div class="card-body card-hotel-customize">
                                
                                        <h2 class="card-title">Presidential Suite</h2>
                                        <p class="card-text">
                                            Spacious, bright guestrooms with tasteful furnishing,
                                            wooden floor and panoramic windows from the ceiling to the
                                            floor.
                                        </p>
                                        <div className="hotel-icons">
                                            <ul>
                                                <li>
                                                    <img src={img1}></img>
                                                    <p>1-2 Persons</p>
                                                </li>
                                                <li>
                                                    <img src={img4}></img>
                                                    <p>Breakfast</p>
                                                </li>
                                            </ul>
                                            <ul>
                                            <li>
                                                    <img src={img2}></img>
                                                    <p>Free Wifi</p>
                                                </li>
                                                <li>
                                                    <img src={img6}></img>
                                                    <p>Jakuzzi</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <img src={img3}></img>
                                                    <p>Dopio + Twin + Living Room</p>
                                                </li>
                                                </ul>
                                       
                                          
                                        </div>
                                        <hr />
                                        <button class="nav-link-button">SHIKO ME SHUME</button>
                                    </div>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <ToTopButton/>
            <Footer />
        </div>
    );
};

export default Rooms;
