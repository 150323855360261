import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Modal from "react-modal";
import phone from "../imgs/phone.png"
import mail from "../imgs/mail.png"
import location from "../imgs/placeholder.png"


const MapGoogle = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(new FormData(e.target)),
    };

    const phpScriptUrl = "https://lakesideks.com/action.php";

    try {
      const response = await fetch(phpScriptUrl, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Set the state to indicate that the form is submitted successfully
      setIsFormSubmitted(true);
      openModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="container-fluid" id="contact">
      
          <div className="container">
      <div className="row map-sec">
        <div className="col-lg-7" data-aos="fade-up" data-aos-duration="1000">
          <div style={{ width: "100%" }}>
            <iframe
              width="100%"
              height="350"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957.2954189319403!2d20.56121297601701!3d42.16536207121169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135375c9f27b2135%3A0x46c2121ae02af649!2sLakeside%20Hotel!5e0!3m2!1sen!2s!4v1696592546966!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
              <a href="https://www.maps.ie/population/">
                Calculate population in area
              </a>
            </iframe>
          </div>
        </div>
        <div className="col-lg-5 contact-top" data-aos="fade-down" data-aos-duration="1000">
          <h1 className="contact-name">Na kontaktoni</h1>
          <div className="row">
          <div className="col-md-4 hi">
            <img src={phone} width={30}/>
            <br></br>
              <a href="tel:+38348808408">+383 48 808 408</a>
          </div>
          <div className="col-md-4 hi">
            <img src={mail} width={30}/>
            <br></br>
              <a href="mailto:info@lakesideks.com">info@lakesideks.com</a>
          </div>
          <div className="col-md-4 hi">
            <img src={location} width={30}/>
            <br></br>
              <a href="https://www.google.com/maps/dir//Lakeside+Hotel+Izer+and+Fatime+Pulaj+V%C3%ABrmic+Prizren+20000/@42.1653621,20.5637879,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x135375c9f27b2135:0x46c2121ae02af649">Vërmicë, Prizren</a>
          </div>
          </div>
          <form
        className="card-form"
        onSubmit={handleSubmit}
        action="/action.php"
        method="post"
      >
            <div class="inputt">
              <input type="text" class="input-field" required id="name" name="name"/>
              <label class="input-label">Name</label>
            </div>

            <div class="inputt">
              <input type="text" class="input-field" required id="surname" name="surname"/>
              <label class="input-label">Surname</label>
            </div>
            <div class="inputt">
              <input type="email" class="input-field" required id="email" name="email"/>
              <label class="input-label">Email</label>
            </div>
            
            <div class="inputt">
              <input type="text" class="input-field" required id="message" name="message" />
              <label class="input-label">Message</label>
            </div>

            <button type="submit" value="submit" >SEND</button>
          </form>
          <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Thank You Modal"
  className="custom-modal"
  overlayClassName="custom-overlay"
>
  <div className="modal-content">
    <h1>Thank you!</h1>
    <p>Ju falenderojme qe na kontaktuat.</p>
    <p>Ne do te ju kontaktojme shume shpejte.</p>
    <button onClick={closeModal}>Close</button>
  </div>
</Modal>
    </div>
        </div>
      </div>
    </div>
  
  );
};

export default MapGoogle;
