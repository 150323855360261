
import React, { useEffect, useState } from 'react';
import d from "../imgs/oferta1.jpg";
import f from "../imgs/oferta2.jpg"
import g from "../imgs/17shkurt.jpg"

const Festat = () => {


  return (
    <div className="container-full" >
      <div className="container-full ">
      
        <div className='row festa'>
        <div className="col-lg-12 about-us-imgs">
        <h1 className='title-about-us'>Për rezervime <span>na kontaktoni!</span></h1></div>
        <div className="col-lg-12 about-us-imgs">
         
            <figure className="info-img">
              <img
                src={d}
                alt=""
                className="f-img bimg"
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </figure>
           {/*  <figure className="info-img">
              <img
                src={f}
                alt=""
                className="f-img bimg"
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </figure> */}
        
         
            
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Festat;
