// WhatsAppButton.js
import React from 'react';
import img1 from '../imgs/whatsapp.png'

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Replace 'PHONE_NUMBER' with the actual phone number, including the country code
    const phoneNumber = 'PHONE_NUMBER';
    const whatsappUrl = `https://wa.me/${38348808408}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleWhatsAppClick}>
      {/* Add your WhatsApp button icon or text here */}
      <img src={img1} width={50} className='whats'/>
    </div>
  );
};

export default WhatsAppButton;
