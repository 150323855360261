import React, { useRef, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Footer from '../components/Footer'
import img1 from "../imgs/wed1.jpg";
import img2 from "../imgs/wed2.jpg";
import img3 from "../imgs/wed3.jpg";
import img4 from "../imgs/wed4.jpg";
import img5 from "../imgs/wed5.jpg";

import startsicon from "../imgs/five-star-customer-product-ratings-review-flat-icons-for-apps-and-websites-illustration-of-five-golden-yellow-stars-in-a-row-isolated-in-a-white-background-concepts-for-ratings-customers-revie-removeb.png"
import ToTopButton from '../components/ToTopButton';

const Wedding = () => {
    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img
                        src={img1}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img2}
                    alt="..."
                /></SwiperSlide>
                <SwiperSlide>
                    <img
                        src={img3}
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={img4}
                    alt="..."
                /></SwiperSlide>
                   <SwiperSlide>                    
                    <img
                    src={img5}
                    alt="..."
                /></SwiperSlide>
                  
                   
            </Swiper>
            <div className='container details-full'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-12 left-detail-part' data-aos="fade-down" data-aos-duration="1000">
                        <img src={startsicon} />
                        <h6>LAKESIDE HOTEL</h6>
                        <h1>Salla e Dasmave</h1>
                        <br />
                        <p>
Titulli: "Bota e Bukur e Dasmoreve: Salla e Dasmave në Hotelin Tonë të Mrekullueshëm"

Përshkrimi:

Në zemër të hotelit tonë të mrekullueshëm, shtrihet një hapësirë e veçantë dhe e mahnitshme e destinuar për të festuar dashurinë tuaj në mënyrë të paharrueshme - Salla e Dasmave. Ky ambient unik, i përshtatur me shije dhe elegancë, është projektuar për të shënuar çdo moment të rëndësishëm të ditës suaj të madhe me stil dhe glamour.</p>
                        <br />

                        <p>Salla jonë e dasmave është një përkujtim i vërtetë i harmonisë dhe klasës, me një dizajn të sofistikuar që kombinon detajet tradicionale me modernen e sotme. Ambienti ynë gjallërohet nga dritat e buta dhe detajet artistike, duke krijuar një atmosferë të ngrohtë dhe sharmante për të pritur mrekullitë e dashurisë së re.</p>
                      
                        <br /><br />
                      
                        <p>Bëni ditën tuaj të madhe të ndriçojë me dritën dhe bukurinë e Sallës së Dasmave në hotelin tonë të mrekullueshëm, një vend i cili do t'ju lërë kujtime të paharrueshme për gjithë jetën.</p>
                    </div>
                  
                </div>
            </div>
            <br /><br/>

            <ToTopButton/>
            <Footer />
        </>
    )
}

export default Wedding