import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import Aos from 'aos';
import 'aos/dist/aos.css';
import img1 from "../imgs/1.jpg";
import a from "../imgs/offer3.jpg";
import b from "../imgs/nata.JPG";
import c from "../imgs/6.jpg";
import img2 from "../imgs/logovet.png";





const HomeBanner = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    Aos.init();
    
    const swiperInstance = swiperRef.current.swiper;
  
    // Function to handle automatic looping from last to first slide
    const handleLoopFromLastToFirst = () => {
      if (swiperInstance) {
        const isLastSlide = swiperInstance.isEnd;
        if (isLastSlide) {
          // If last slide, smoothly transition to the first slide
          swiperInstance.slideTo(0, 1000, false); // Change 1000 to adjust transition speed in milliseconds
        } else {
          swiperInstance.slideNext(); // Go to the next slide as usual
        }
      }
    };
  
    // Set interval to automatically handle looping
    const intervalId = setInterval(handleLoopFromLastToFirst, 5000); // Adjust the interval as needed
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  

  return (
    <div>
       <Swiper
                   ref={swiperRef}
                   slidesPerView={1}
                   spaceBetween={0}
                   navigation
                   pagination={{ clickable: true }}
                   className="mySwiper my"
                
            >
           

                {/* <SwiperSlide className="tet">
                <img src={f} alt="..."  className="tetmarsi"/>
                <div className="slide-content dasma">
    
    <div className="text-overlay text-home pertet">
      <img src={img2}></img>
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Rezervoni vendin tuaj </span> <br className="br1" />
        për 14 shkurt ! <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>
                <SwiperSlide className="tet1">
                <img src={g} alt="..."  className="tetmarsi"/>
                <div className="slide-content dasma">
    
    <div className="text-overlay text-home pertet">
      <img src={img2}></img>
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Rezervoni vendin tuaj </span> <br className="br1" />
        dhe festoni 17 shkurtin ! <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>
                <SwiperSlide className="tet2">
                <img src={d} alt="..."  className="tetmarsi"/>
                <div className="slide-content ">
    
    <div className="text-overlay text-home pertet">
      <img src={img2}></img>
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Rezervoni vendin tuaj </span> <br className="br1" />
        për ditën e gruas ! <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide> */}



                <SwiperSlide>
                <img src={img1} alt="..." />
                <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>

                <SwiperSlide>                    
                    <img
                    src={a}
                    alt="..." className="offer"/>
                               <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src={b}
                        alt="..."
                    />
                                <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
           
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={c}
                    alt="..."
                />
                                            <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
        
                </SwiperSlide>
            </Swiper>
  
    </div>
  );
};

export default HomeBanner;
