import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../imgs/lake.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <>
      <nav
        className="navbar navbar-expand-md navbar-light bg-light"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              alt=""
              className="logoja"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav" id="change-direct">
              <li className={`nav-item ${activeLink === "/" ? "active" : ""}`}>
                <Link to="/" className="nav-link">
                  Ballina
                </Link>
              </li>
              <li className={`nav-item ${activeLink.includes("/#about") ? "active" : ""}`}>
                <HashLink smooth to="/#about" className="nav-link">
                  Rreth nesh
                </HashLink>
              </li>
              <li className={`nav-item ${activeLink === "/Rooms" ? "active" : ""}`}>
                <Link to="/Rooms" className="nav-link">
                  Dhomat
                </Link>
              </li>
              <li className={`nav-item ${activeLink === "/spa" ? "active" : ""}`}>
                <Link to="/spa" className="nav-link">
                  SPA dhe Pishina
                </Link>
              </li>
              <li className={`nav-item ${activeLink === "/wedding" ? "active" : ""}`}>
                <Link to="/wedding" className="nav-link">
                  Salla e dasmave
                </Link>
              </li>
              <li className={`nav-item ${activeLink === "/bar" ? "active" : ""}`}>
                <Link to="/bar" className="nav-link">
                  Bari i natës
                </Link>
              </li>
              <li className={`nav-item ${activeLink === "/restaurant" ? "active" : ""}`}>
                <Link to="/restaurant" className="nav-link">
                  Restauranti
                </Link>
              </li>
              <li className="nav-item">
  <a href="https://www.lakesideks.com/Menu.pdf" className="nav-link" target="_blank" rel="noopener noreferrer">
    Menu Restauranti
  </a>
</li>
              <li className={`nav-item ${activeLink.includes("/#contact") ? "active" : ""}`}>
                <HashLink smooth to="/#contact" className="nav-link-button">
                  NA KONTAKTONI
                </HashLink>
              </li>
              <br className="dp-none"/>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
