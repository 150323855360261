import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import img2 from "../imgs/five-star-customer-product-ratings-review-flat-icons-for-apps-and-websites-illustration-of-five-golden-yellow-stars-in-a-row-isolated-in-a-white-background-concepts-for-ratings-customers-revie-removeb.png";
import img3 from "../imgs/icons8-phone-48.png";
import img4 from "../imgs/logo.svg";
import nje from "../imgs/IMG_0768.jpg"
import dy from "../imgs/image00003.jpeg"

const AboutUs = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div  >
      <div className="container-fluid about-part" id="about">
        <div className="row">
          <div
            className="col-lg-6 about-left-part-home"
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            <img src={img2} alt="" className="star-icons" />
            <p>LAKESIDE HOTEL</p>
            <h1 className="title-about-us">
              Shijo një eksperiencë <br /> <span>luksoze</span>
            </h1>
            <br />
            <p>
            LAKESIDE HOTEL SPA AND CONFERENCE është një hotel luksoz me pesë yje që ndodhet në Vermicë të Prizrenit, një nga vendet më të bukura në Kosovë. 
            I vendosur në një vend të qetë, hoteli u ofron klientëve një pamje mahnitëse të liqenit dhe maleve që e rrethojnë.
            </p>
            <p>
            Hoteli ndodhet pranë liqenit, i cili u ofron mysafirëve njëshumëllojshmëri aktivitetesh ujore si noti, lundrimi dhe peshkimi.Ky hotel luksoz u ofron klientëve një përvojë të pashembullt, me pajisje të klasit botëror dhe shërbim të jashtëzakonshëm.Pavarësisht nëse jeni në LAKESIDE HOTEL për biznes apo për argëtim, hoteli ofron një kombinim perfekttë komoditetit dhe luksit. Në këtë hotel do të përjetoni bukurinë e Vermicës dhe rrethinat e saj, duke shijuar mikpritjen tonë të klasit botëror.
            </p>
            <br />
            <div className="tel-div">
              <img src={img3} alt="" className="phone-icon" />
              <div className="margin-text">
                <p className="reserve-text">Reservation</p>
                <h1 className="num-text"><a href="tel:+38348808408">+383 48 808 408</a></h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6 about-us-imgs">
            <figure className="info-img">
              <img
                src={nje}
                alt=""
                className="f-img"
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </figure>
            <img
              src={img4}
              alt=""
              className="th-img"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
            <figure className="info-img">
              <img
                src={dy}
                alt=""
                className="s-img"
                data-aos="fade-down"
                data-aos-duration="2000"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
