import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import logo from "../imgs/lake.png";
import  img1 from "../imgs/04.jpg"
import img2 from "../imgs/image3.jpeg"
import img3 from "../imgs/bar.jpg"
import { Link } from "react-router-dom";

const ExclusiveOffers = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="container exclusive">
      <div className="row margin-top-exclusive">
        <div className="col-lg-3" data-aos="fade-up" data-aos-duration="1000">
          <div className="welcome-exclusive-card">
            <img
              src={logo}
              alt=""
              width={200}
              
            />
            <div>
              <h1>
                Oferta <br /> ekskluzive
              </h1>
              <br />
              <p>OFERTAT E FUNDIT</p>
              <br />
              <button>SHIKO ME SHUME</button>
            </div>
           
          </div>
        </div>
        <div className="col-lg-3" data-aos="fade-up" data-aos-duration="2000">
          <div class="card remove-round" style={{ width: "100%" }}>
            <img
              src={img1}
              class="card-img-top im"
              alt="..."
            />
            <div class="card-body">
              <h4 class="card-title">Salla e Dasmave</h4>
              <h6>E hapur</h6>
              <br />
              <p class="card-text">
              Ambienti i ngrohtë dhe i përshtatshëm, bashkë me një staf të përkushtuar, do të sigurojnë një dasmë të paharrueshme.
              </p>
              <button className="but"><Link to='./wedding'>SHIKO ME SHUME</Link></button>
            </div>
          </div>
        </div>
        <div className="col-lg-3" data-aos="fade-down" data-aos-duration="2000">
          <div class="card remove-round" style={{ width: "100%" }}>
            <img
              src={img2}
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h4 class="card-title">Dhoma e konferencave</h4>
              <h6>E hapur</h6>
              <br />
              <p class="card-text">
              Dizajni i modernizuar dhe shërbimi i përkushtuar e bëjnë këtë dhomë një zgjedhje të shkëlqyer për ngjarjet e suksesshme të biznesit.
              </p>
              <button>SHIKO ME SHUME</button>
            </div>
          </div>
        </div>
        <div className="col-lg-3" data-aos="fade-down" data-aos-duration="2000">
          <div class="card remove-round" style={{ width: "100%" }}>
            <img
              src={img3}
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h4 class="card-title">Bari i nates</h4>
              <h6>E hapur</h6>
              <br />
              <p class="card-text">
             I projektuar me kujdes për të ofruar një atmosferë të qetë dhe të relaksuar, duke përdorur dritat e ulëta dhe dizajnin e brendshëm të butë.
              </p>
              <button className="but"><Link to='./bar'>SHIKO ME SHUME</Link></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveOffers;
