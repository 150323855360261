import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import logo from "../imgs/lakees.png";
import fblogo from "../imgs/icons8-facebook-30 (3).png";
import iglogo from "../imgs/icons8-instagram-32 (3).png";
import twlogo from "../imgs/icons8-twitter-50 (3).png";

const Footer = () => {
    useEffect(() => {
        Aos.init();
    }, []);
    return (
        <div className="container-fluid footer-home">
            <div className="container" data-aos="fade-down" data-aos-duration="1000">
                <div
                    className="row d-flex justify-content-center text-style"
                    id="title-styles"
                >
                    <div className="col-lg-7">
                        <div
                            className="row d-flex align-items-baseline"
                            id="margin-between"
                        >
                            <div className="col-lg-4 hh">
                                <Link to="/home">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="logoja"
                                    />
                                </Link>
                                <h5>LAKESIDE <br></br> HOTEL</h5>
                            </div>
                            <div className="col-lg-4">
                                <h1>Rreth Lakeside</h1>
                                <p>
                                Ky hotel luksoz u ofron klientëve një përvojë të pashembullt, me pajisje të klasit botëror dhe shërbim të jashtëzakonshëm.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h1>Site Map</h1>
                                <ul>
                                    <Link to="/home" style={{textDecoration:"none"}}>
                                    <li>Ballina</li>
                                </Link> 
                                <Link to="/" style={{textDecoration:"none"}}>
                                    <li>Rreth nesh</li>
                                </Link> 
                                <Link to="/Rooms" style={{textDecoration:"none"}}>
                                    <li>Dhomat</li>
                                </Link> 
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 d-flex" id="social-medias">
                        <div className="col-lg-6">
                            <h1>Rrjetet sociale</h1>
                            <div className="imgs-footer">
                                <Link to="https://www.facebook.com/lakesideks/"><img src={fblogo} alt="" /></Link>
                                <Link to="https://www.instagram.com/lakesideks/"><img src={iglogo} alt="" /></Link>
                                <Link to="https://www.facebook.com/lakesideks/"><img src={twlogo} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="cr-down">
                    <h1>Copyright © 2023 LakeSide Hotel - <a href="https://dolphinsgroup.eu/">Dolphins Group</a></h1>
                </div>
            </div>
        </div>
    );
};

export default Footer;
