import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";


const BannerSec = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="banner-srvc">
      <h1 data-aos="fade-up" data-aos-duration="1000" id="first-width">
       Vendi i duhur për <br className="br2"/> kujtimet më të mira
      </h1>
      <p data-aos="fade-up" data-aos-duration="1000" id="width-par">
      Ambienti i bukur dhe shërbimi i kujdesshëm e bëjnë atë një destinacion të paharrueshëm .<br className="br1"/> për kujtimet më të bukura të udhëtimit tuaj
       
      </p>
      <button data-aos="fade-up" data-aos-duration="1000">ME SHUME</button>
    </div>
  );
};

export default BannerSec;
