import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import a1 from "../imgs/restaurant.png"
import a2 from "../imgs/spa.png"
import a3 from "../imgs/hall.png"
import a4 from "../imgs/swimming.png"
import a5 from "../imgs/board-meeting.png"
import a6 from "../imgs/weight.png"

const Feature = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="container-fluid mt-3" id='gray'>
        <div className="row d-flex align-items-center m-auto p-4">
          <div
            className="col-lg-6 activities-txt"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1>Shërbimet tona</h1>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={a1}
                  alt=""
                />
                <h1>Restaurant</h1>
                <p>Shijo kuzhinë të shijshme dhe autentike lokale.</p>
              </div>
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <img
                  src={a2}
                  alt=""
                />
                <h1>SPA</h1>
                <p>Përjeto një ndjesi unike dhe relaks.</p>
              </div>
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img
                  src={a3}
                  alt=""
                />
                <h1>Salla e dasmave</h1>
                <p>Përjeto një dasmë te paharrueshme.</p>
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={a4}
                  alt=""
                />
                <h1>Pishinat</h1>
                <p>Rivendosni ekuilibrin tuaj me pishinat tona.</p>
              </div>
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <img
                  src={a5}
                  alt=""
                />
                <h1>Dhoma e konferencave</h1>
                <p>Destinacioni i përsosur për cdo takim.</p>
              </div>
              <div
                className="col-lg-4 rect"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img
                  src={a6}
                  alt=""
                />
                <h1>Fitness</h1>
                <p>Stervitje e duhur për trup të shëndetshëm.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
