import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Modal from "react-modal";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import img1 from '../imgs/dhoma1.JPG';
import img2 from '../imgs/Banjo per tana dhomat.jpg';
import img3 from '../imgs/Pamja e krejt dhomave.jpg';
import img4 from '../imgs/dhoma2.JPG';
import img5 from '../imgs/gjak.jpg';
import img6 from '../imgs/gjakuzi.jpg';
import img7 from '../imgs/18.jpg';
import img8 from '../imgs/Model 2 per banjo.jpg'
import img9 from '../imgs/Egzekutiv Room (2).jpg'
import img10 from '../imgs/Egzekutiv Room.jpg'
import img12 from '../imgs/Apartament.jpg'
import img13 from '../imgs/Apartman.jpg'
import img14 from '../imgs/Quin Room.jpg'

import FsLightbox from "fslightbox-react";

const BookCards = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [room, setRoom] = useState("");
  const[name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const openReservationModal = (roomType) => {
    // Set the room value based on the roomType parameter
    setRoom(roomType);
  
    // Open the reservation modal
    setIsReservationModalOpen(true);
  };
  const closeReservationModal = () => {
    setIsReservationModalOpen(false);
  };

  const openThankYouModal = () => {
    setIsThankYouModalOpen(true);
  };

  const closeThankYouModal = () => {
    setIsThankYouModalOpen(false);
  };

  const handleReserve = () => {
    // Close the reservation modal
    closeReservationModal();

    // Open the thank-you modal
    openThankYouModal();
  };

  const handleSubmit = async () => {
    try {
      // Send reservation details to the server (PHP) via API
      const response = await fetch("https://lakesideks.com/standardroom.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
      
          name,
          surname,
          phone,
          email,
          room,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Optionally, handle the success response
      console.log("Reservation submitted successfully!");

      // Open the thank-you modal after successful submission
      openThankYouModal();
    } catch (error) {
      console.error("Error submitting reservation:", error);
    }
  };

  const [toggler, setToggler] = useState(false);
  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);
  const [toggler4, setToggler4] = useState(false);
  const [toggler5, setToggler5] = useState(false);





  return (
    <div className="body">
 
      <div className="center-text-book">
        <p data-aos="fade-up" data-aos-duration="1000">
          DHOMAT TONA
        </p>
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Inspiruese, dizajn i <br />
          jashtëzakonshëm në <span>LakeSide Hotel!</span>
        </h1>
        <div
          className="line-gold"
          data-aos="fade-up"
          data-aos-duration="1000"
        ></div>
      </div>
      <div className="container">

      <FsLightbox
				toggler={toggler}
				sources={[
				img1,img2,img3
				]}
			/>
      <FsLightbox
				toggler={toggler1}
				sources={[
				img4,img2,img3
				]}
			/>
      <FsLightbox
				toggler={toggler2}
				sources={[
				img12,img2,img3,img13
				]}
			/>
      <FsLightbox
				toggler={toggler3}
				sources={[
				img7,img8,img14
				]}
			/>
      <FsLightbox
				toggler={toggler4}
				sources={[
				img5,img6,img7
				]}
			/>
      <FsLightbox
				toggler={toggler5}
				sources={[
				img9,img10,img7,img5,img6
				]}
			/>
     
        <main class="page-content">
          <div class="cardd" data-aos="fade-up" data-aos-duration="1000">
            <div class="content">
              <h2 class="title">Dhoma Standarde</h2>
              <p className="copy">80€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"
  onClick={() => setToggler(!toggler)}>

  <p>View Photos</p>
</button>
              <button class="btnn"  onClick={() => openReservationModal('Dhoma Standarde - 80€ / NATA')} ><p  >Book Now</p></button></div>
            </div>
          </div>
          <div class="cardd" data-aos="fade-up" data-aos-duration="2000">
            <div class="content">
              <h2 class="title">Dhoma Twin</h2>
              <p className="copy">89€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"
  onClick={() => setToggler1(!toggler1)}
>
  <p>View Photos</p>
</button>
              <button class="btnn"onClick={() => openReservationModal('Dhoma Twin - 89€ / NATA')} ><p  >Book Now</p></button>
            </div>
            </div>
          </div>
          <div class="cardd" data-aos="fade-up" data-aos-duration="3000">
            <div class="content">
              <h2 class="title">Apartment</h2>
              <p className="copy">109€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"
  onClick={() => setToggler2(!toggler2)}
>
  <p>View Photos</p>
</button>
              <button class="btnn" onClick={() => openReservationModal('Apartament - 109€ / NATA')} ><p>Book Now</p></button>
            </div>
            </div>
          </div>
        
        
          <div class="cardd" data-aos="fade-up" data-aos-duration="1000">
            <div class="content">
              <h2 class="title">Queen Room</h2>
              <p className="copy">129€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"
  onClick={() => setToggler3(!toggler3)}
>
  <p>View Photos</p>
</button>
              <button class="btnn" onClick={() => openReservationModal('Queen Room - 129€ / NATA')} ><p  >Book Now</p></button>
            </div></div>
          </div>
          <div class="cardd" data-aos="fade-up" data-aos-duration="2000">
            <div class="content">
              <h2 class="title">Junior Suite</h2>
              <p className="copy">189€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"
  onClick={() => setToggler4(!toggler4)}
>
  <p>View Photos</p>
</button>
              <button class="btnn" onClick={() => openReservationModal('Junior Suite - 189€ / NATA')} ><p  >Book Now</p></button>
            </div></div>
          </div>
          
          <div class="cardd" data-aos="fade-up" data-aos-duration="3000">
            <div class="content">
              <h2 class="title">Executive Suite</h2>
              <p className="copy">289€ / NATA</p>
              <p className="copy">
                Spacious, bright guestrooms with tasteful furnishing, wooden
                floor and panoramic windows from the ceiling to the floor.
              </p>
              <div className="butonss">
              <button
  className="btnn"  onClick={() => setToggler5(!toggler5)}
>
  <p>View Photos</p>
</button>
              <button class="btnn" onClick={() => openReservationModal('Executive Suite - 289€ / NATA')} ><p  >Book Now</p></button>
            </div></div>
          </div>
        </main>
      </div>

      {/* Reservation Form Modal */}
      <Modal
        isOpen={isReservationModalOpen}
        onRequestClose={closeReservationModal}
        contentLabel="Reservation Form Modal"
        overlayClassName="reservation-overlay"
        ariaHideApp={false}
        className="modali"
      >
        <div className="reservation-content">
        <p>Due to the maintaince of the website please call this number:</p>
        <p>+383 48 808 408</p>
          {/* <h2>Reservation Details</h2>
          <p><b>Check-in:</b> {checkIn}</p>
          <p><b>Check-out:</b> {checkOut}</p>
          <p><b>Adults:</b> {adults}</p>
          <p><b>Children:</b> {children}</p>

          <div className="form-group nisi">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname:</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}

          <div className="butonat">
            {/* <button onClick={() => { handleSubmit(); handleReserve(); }} className="w">
              Reserve
            </button> */}

            <button onClick={closeReservationModal} className="wi">
              Close
            </button>
          </div>
        </div>
      </Modal>

      {/* Thank You Modal */}
      <Modal
        isOpen={isThankYouModalOpen}
        onRequestClose={closeThankYouModal}
        contentLabel="Thank You Modal"
        ariaHideApp={false}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <div className="modal-content">
          <h1>Thank you!</h1>
          <p>Ju falenderojme per rezervimit.</p>
          <p>Ne do te ju kontaktojme shume shpejte.</p>
          <button onClick={closeThankYouModal}>Close</button>
        </div>
      </Modal>
    </div>
 
  );
}

export default BookCards